<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      width="800px"
      v-drag
      @closed="dialogClosed"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="elform"
        :model="formData"
        label-width="130px"
        size="small"
        :rules="rules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="产品大类"
              prop="firstCateId"
            >
              <el-select
                v-model="formData.firstCateId"
              >
                <el-option
                  v-for="(item, index) in firstPro"
                  :key="index"
                  :label="item.cateName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="品牌"
              prop="brandCateId"
            >
              <el-select
                v-model="formData.brandCateId"
              >
                <el-option
                  v-for="(item, index) in secondPro"
                  :key="index"
                  :label="item.cateName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="机型名称"
              prop="modelName"
            >
              <el-input
                placeholder="请输入"
                v-model.trim="formData.modelName"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item
              label="系统分配分类ID"
              prop="sign"
            >
              <el-input
                placeholder="系统分配分类ID"
                v-model.trim="formData.sign"
                readonly
              ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item
              label="基础估值"
              prop="basicPrice"
            >
              <el-input
                placeholder="请输入"
                v-model.trim="formData.basicPrice"
                @input="numberCtrol(formData, 'basicPrice', 2, 20)"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="机型展示图片"
              prop="imageUrl"
            >
              <l-upload-img
                ref="LUploadImg"
                :limit="1"
                :fileUrl.sync="formData.imageUrl"
                :folderName="'product'"
                @getFile="getFile"
              ></l-upload-img>
            </el-form-item>
          </el-col>
          <el-col>
            <el-col :span="6">
              <el-button
                size="mini"
                type="success"
                icon="el-icon-circle-plus"
                @click="addProConfig"
              >增加产品配置项
              </el-button>
            </el-col>
            <el-col :span="18">
              <template v-for="(pitem, pindex) in formData.productOptionsList">
                <el-col :key="pindex">
                  <el-col :span="9">
                    <el-form-item
                      label-width="0"
                      :prop="`productOptionsList[${pindex}].opsCode`"
                      :rules="[{ required: true, message: '请选择', trigger: 'change' }]"
                    >
                      <el-select
                        v-model="pitem.opsCode"
                      >
                        <el-option
                          v-for="(item, index) in filterDictionary('0111')"
                          :key="index"
                          :label="item.name"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="9" :push="1">
                    <el-form-item
                      label-width="0"
                      :prop="`productOptionsList[${pindex}].opsValue`"
                      :rules="[{ required: true, message: '请输入', trigger: 'change' }]"
                    >
                      <el-input
                        placeholder="请输入"
                        v-model.trim="pitem.opsValue"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4" :push="1" style="text-align:center">
                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="delOptions(pindex)"></el-button>
                  </el-col>
                </el-col>
              </template>
            </el-col>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="dialogVisible = false"
        >取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="submitForm"
          :loading="loading"
        >保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { numberCtrol } from '@/utils/utils';
import LUploadImg from '@/components/LUploadImg.vue';
import api from '@/api/ProManageApi';
import appApi from '@/api/app';

export default {
  name: 'AddModel',
  components: {
    LUploadImg,
  },
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '新增角色',
      loading: false,
      formData: {
        firstCateId: '',
        brandCateId: '',
        modelName: '',
        basicPrice: '',
        imageUrl: '',
        productOptionsList: [],
      },
      rules: {
        firstCateId: [{ required: true, message: '请选择', trigger: 'change' }],
        brandCateId: [{ required: true, message: '请选择', trigger: 'change' }],
        modelName: [{ required: true, message: '请输入', trigger: 'change' }],
        basicPrice: [{ required: true, message: '请输入', trigger: 'change' }],
        imageUrl: [{ required: true, message: '请选择', trigger: 'change' }],
      },
      firstPro: [],
      secondPro: [],
    };
  },
  computed: {
  },
  watch: {
    'formData.firstCateId': {
      handler(nval) {
        this.getSecondPro(2, nval);
      },
    },
  },
  created() {
    this.getFirstPro();
  },
  methods: {
    numberCtrol,
    // 获取品牌产品
    getSecondPro(cateLevel, parentCateId) {
      appApi.brand(
        {
          cateLevel,
          parentCateId,
        },
      )
        .then(res => {
          this.secondPro = res.result;
        }).catch(err => {
          console.log(err);
        });
    },
    // 获取大类产品
    getFirstPro() {
      appApi.first()
        .then(res => {
          this.firstPro = res.result;
        }).catch(err => {
          console.log(err);
        });
    },
    delOptions(i) {
      this.formData.productOptionsList.splice(i, 1);
    },
    addProConfig() {
      console.log(this.formData);
      const obj = {
        opsCode: '',
        opsValue: '',
      };
      this.formData.productOptionsList.push(obj);
    },
    getFile(files) {
      const arr = files.map(val => val.name);
      this.formData.imageUrl = arr.join(',');
    },
    submitForm() {
      this.$refs.elform.validate(valid => {
        if (valid) {
          this.loading = true;
          const {
            id,
            firstCateId,
            brandCateId,
            modelName,
            basicPrice,
            imageUrl,
            productOptionsList,
          } = this.formData;
          if (this.dialogTitle === '新增机型') {
            api.modeladd(
              {
                firstCateId,
                brandCateId,
                modelName,
                basicPrice,
                imageUrl,
                optionsList: productOptionsList,
              },
            )
              .then(res => {
                this.dialogVisible = false;
                this.$message.success(res.message);
                this.$emit('refreshTable');
              })
              .catch(err => {
                console.log(err);
                this.$message.error(err.message);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            api.modelmodify(
              {
                id,
                firstCateId,
                brandCateId,
                modelName,
                basicPrice,
                imageUrl,
                optionsList: productOptionsList,
              },
            )
              .then(res => {
                this.dialogVisible = false;
                this.$message.success(res.message);
                this.$emit('refreshTable');
              })
              .catch(err => {
                console.log(err);
                this.$message.error(err.message);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    dialogClosed() {
      this.$refs.elform.resetFields();
      this.$refs.LUploadImg.clearFiles();
      this.formData.productOptionsList = [];
    },
  },
};
</script>

<style lang="scss" scoped>
  /deep/.el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
  /deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 100px;
    height: 100px;
  }
</style>
