import { postInfo } from './api';

export default {
  // 产品机型列表
  modellist(data) {
    return postInfo('/product/model/list', data);
  },
  // 添加机型
  modeladd(data) {
    return postInfo('/product/model/add', data);
  },
  // 修改机型
  modelmodify(data) {
    return postInfo('/product/model/modify', data);
  },
  // 删除机型
  modeldel(data) {
    return postInfo('/product/model/del', data);
  },
  // 机型上架、下架
  shelves(data) {
    return postInfo('/product/model/shelves', data);
  },
  // 机型上架、下架
  modelget(data) {
    return postInfo('/product/model/get', data);
  },
};
